import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import { Indent, NegativeMobile } from 'core/components/Wrappers';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';
import resolveRelationships from 'core/utils/relationships';

import TopicHeader from 'site/pages/topic/TopicHeader';
import TopicWrapper from 'site/pages/topic/TopicWrapper';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard({ content, isMobile }) {
  return (
    <Topic content={content}>
      {topic => {
        const {
          content: {
            widgets,
          },
        } = relationships(topic);

        if (widgets.length === 0) return null;

        const {
          final_announce: finalAnnounce,
        } = widgets[0].attributes;

        return (
          <TopicWrapper content={topic}>
            <Indent top={isMobile ? 16 : 20} bottom={20}>
              <TopicHeader content={topic} isGameCompare />
            </Indent>
            <div>{finalAnnounce}</div>
            <Indent top={isMobile ? 35 : 25} />
            <NegativeMobile>
              <LeaderBoardCore content={topic} />
            </NegativeMobile>
            <Indent top={isMobile ? 80 : 50} />
          </TopicWrapper>
        );
      }}
    </Topic>
  );
}

LeaderBoard.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withPageHocs(topicProvider)(LeaderBoard));
